exports.list = {
    path: '/list_stock_inventory',
    name: 'list_stock_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_stock_inventory/List.vue"),
}
exports.show = {
    path: '/list_stock_inventory/show/:kode',
    name: 'list_stock_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_stock_inventory/Show.vue"),
}