exports.list = {
    path: '/jurnal_memorial',
    name: 'jurnal_memorial', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/jurnal_memorial/List.vue"),
}
exports.show = {
    path: '/jurnal_memorial/show/:no_jm',
    name: 'jurnal_memorial_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/jurnal_memorial/Show.vue"),
}