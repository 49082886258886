exports.list = {
    path: '/merk',
    name: 'merk', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/merk/List.vue"),
}
exports.show = {
    path: '/merk/show/:kode',
    name: 'merk_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/merk/Show.vue"),
}