exports.list = {
  path: "/rakit_barang",
  name: "rakit_barang",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/rakit_barang/List.vue"),
};
exports.show = {
  path: "/rakit_barang/show/:kode",
  name: "rakit_barang_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/rakit_barang/Show.vue"),
};
  