exports.list = {
    path: '/pencairan_card_terima',
    name: 'pencairan_card_terima', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pencairan_card_terima/List.vue"),
}
exports.show = {
    path: '/pencairan_card_terima/show/:no_kk',
    name: 'pencairan_card_terima_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pencairan_card_terima/Show.vue"),
}