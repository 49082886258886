exports.list = {
    path: '/divisi',
    name: 'divisi', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/divisi/List.vue"),
}
exports.show = {
  path: '/divisi/show/:kode',
  name: 'divisi_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/divisi/Show.vue"),
}