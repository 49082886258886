exports.list = {
    path: '/pengeluaran_inventory',
    name: 'pengeluaran_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pengeluaran_inventory/List.vue"),
}
exports.show = {
    path: '/pengeluaran_inventory/show/:no_transaksi',
    name: 'pengeluaran_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pengeluaran_inventory/Show.vue"),
}