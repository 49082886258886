exports.list = {
    path: '/pemasukan_inventory',
    name: 'pemasukan_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pemasukan_inventory/List.vue"),
}
exports.show = {
    path: '/pemasukan_inventory/show/:no_transaksi',
    name: 'pemasukan_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pemasukan_inventory/Show.vue"),
}