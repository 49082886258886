exports.list = {
  path: '/vulkanisir',
  name: 'vulkanisir', 
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/vulkanisir/List.vue")
};
exports.show = {
  path: "/vulkanisir/show/:kode",
  name: "vulkanisir_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/vulkanisir/Show.vue"),
};
  