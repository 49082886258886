exports.list = {
    path: '/penyusutan_aktiva',
    name: 'penyusutan_aktiva', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penyusutan_aktiva/List.vue"),
}
exports.show = {
    path: '/penyusutan_aktiva/show/:no_kk',
    name: 'penyusutan_aktiva_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penyusutan_aktiva/Show.vue"),
}