exports.list = {
    path: '/adjustment_inventory',
    name: 'adjustment_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/adjustment_inventory/List.vue"),
}
exports.show = {
    path: '/adjustment_inventory/show/:no_transaksi',
    name: 'adjustment_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/adjustment_inventory/Show.vue"),
}