exports.list = {
    path: '/piutang_dagang',
    name: 'piutang_dagang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/piutang_dagang/List.vue"),
}
exports.show = {
    path: '/piutang_dagang/show/:no_kk',
    name: 'piutang_dagang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/piutang_dagang/Show.vue"),
}