exports.list = {
    path: '/hutang_lain',
    name: 'hutang_lain', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/hutang_lain/List.vue"),
}
exports.show = {
    path: '/hutang_lain/show/:kode',
    name: 'hutang_lain_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/hutang_lain/Show.vue"),
}