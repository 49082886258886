exports.list = {
    path: '/saldo_awal',
    name: 'saldo_awal', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/saldo_awal/List.vue"),
}
exports.show = {
    path: '/saldo_awal/show/:_id',
    name: 'saldo_awal_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/saldo_awal/Show.vue"),
}