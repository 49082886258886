exports.list = {
    path: '/mutasi',
    name: 'mutasi_list', 
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/main/mutasi/List.vue")
};
exports.show = {
    path: '/mutasi/show/:no_transaksi',
    name: 'mutasi_show', 
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/main/mutasi/Show.vue")
};