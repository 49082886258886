exports.list = {
    path: '/aktivitas_barang',
    name: 'aktivitas', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/aktivitas_barang/List.vue"),
}
exports.show = {
    path: '/aktivitas_barang/show/:kode',
    name: 'aktivitas_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/aktivitas_barang/Show.vue"),
}