exports.list = {
  path: '/akun',
  name: 'Akun', 
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/akun/List.vue")
};
exports.show = {
  path: '/akun/show/:kode',
  name: 'akun_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/akun/Show.vue"),
}
  