exports.list = {
    path: '/master_mph',
    name: 'master_mph', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/master_mph/List.vue"),
}
exports.show = {
  path: '/master_mph/show/:no_faktur',
  name: 'master_mph_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/master_mph/Show.vue"),
}