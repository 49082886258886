exports.list = {
    path: '/tutup_po',
    name: 'tutup_po', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/tutup_po/List.vue"),
}
exports.show = {
    path: '/tutup_po/show/:no_transaksi',
    name: 'tutup_po_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/tutup_po/Show.vue"),
}