exports.list = {
    path: '/purchase_order',
    name: 'purchase_order', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/purchase_order/List.vue"),
}
exports.show = {
    path: '/purchase_order/show/:no_po',
    name: 'purchase_order_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/purchase_order/Show.vue"),
}