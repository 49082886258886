exports.list = {
    path: '/item_inventory',
    name: 'item_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/item_inventory/List.vue"),
}
exports.show = {
    path: '/item_inventory/show/:kode',
    name: 'item_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/item_inventory/Show.vue"),
}
exports.upload = {
    path: '/item_inventory/upload',
    name: 'item_inventory_upload', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/item_inventory/Upload.vue"),
}