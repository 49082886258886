exports.list = {
    path: '/stokopname_inventory',
    name: 'stokopname_inventory', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/stokopname_inventory/List.vue"),
}
exports.show = {
    path: '/stokopname_inventory/show/:no_transaksi',
    name: 'stokopname_inventory_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/stokopname_inventory/Show.vue"),
}
exports.upload = {
    path: '/stokopname_inventory/upload/:no_transaksi',
    name: 'stokopname_inventory_upload', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/stokopname_inventory/Upload.vue"),
}