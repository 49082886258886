exports.list = {
  path: '/pelanggan',
  name: 'pelanggan', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/pelanggan/List.vue"),
}
exports.show = {
  path: '/pelanggan/show/:kode',
  name: 'pelanggan_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/pelanggan/Show.vue"),
}