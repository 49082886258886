exports.list = {
    path: '/pattern',
    name: 'pattern', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/pattern/List.vue"),
}
exports.show = {
  path: '/pattern/show/:id',
  name: 'pattern_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/pattern/Show.vue"),
}