exports.list = {
    path: '/piutang_lain',
    name: 'piutang_lain', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/piutang_lain/List.vue"),
}
exports.show = {
    path: '/piutang_lain/show/:kode',
    name: 'piutang_lain_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/piutang_lain/Show.vue"),
}