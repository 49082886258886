exports.list = {
    path: '/penomoran',
    name: 'penomoran', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/penomoran/List.vue"),
}
exports.show = {
  path: '/penomoran/show/:id',
  name: 'penomoran_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/penomoran/Show.vue"),
}