exports.list = {
    path: '/penjualan_retail',
    name: 'penjualan_retail', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/penjualan_retail/List.vue"),
}
exports.show = {
  path: '/penjualan_retail/show/:no_faktur',
  name: 'penjualan_retail_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/penjualan_retail/Show.vue"),
}