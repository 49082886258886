exports.group = {
    path: '/group',
    name: 'group', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/groupList.vue"),
}
exports.group_show = {
    path: '/group/show/:id',
    name: 'group_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/groupShow.vue"),
}
exports.user = {
    path: '/user',
    name: 'user', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/UserList.vue"),
}
exports.user_show = {
    path: '/user/show/:kode',
    name: 'user_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/userShow.vue"),
}
exports.group_cabang = {
    path: '/group_cabang',
    name: 'group_cabang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/GroupCabangList.vue"),
}
exports.group_cabang_show = {
    path: '/group_cabang/show/:id',
    name: 'group_cabang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/GroupCabangShow.vue"),
}
  