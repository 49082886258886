exports.list = {
    path: '/kas_bank_masuk',
    name: 'kas_bank_masuk', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_bank_masuk/List.vue"),
}
exports.show = {
    path: '/kas_bank_masuk/show/:no_kk',
    name: 'kas_bank_masuk_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_bank_masuk/Show.vue"),
}