  exports.list = {
    path: '/karyawan',
    name: 'karyawan', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/karyawan/List.vue"),
  }
  exports.show = {
    path: '/karyawan/show/:kode',
    name: 'karyawan_show', 
    meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/karyawan/Show.vue"),
  };
  