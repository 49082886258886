exports.list = {
    path: '/permintaan_mutasi',
    name: 'permintaan_mutasi_list', 
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/main/permintaan_mutasi/List.vue")
  };
  exports.show = {
    path: '/permintaan_mutasi/show/:no_transaksi',
    name: 'permintaan_mutasi_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/permintaan_mutasi/Show.vue"),
}