exports.list = {
    path: '/supplier',
    name: 'supplier', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/supplier/List.vue"),
}
exports.show = {
    path: '/supplier/show/:kode',
    name: 'supplier_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/supplier/Show.vue"),
}