exports.list = {
    path: '/jasa',
    name: 'Jasa', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/jasa/List.vue"),
}
exports.show = {
    path: '/jasa/show/:kode',
    name: 'Jasa_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/jasa/Show.vue"),
}