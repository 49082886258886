import { createStore } from 'vuex'
import { getFeed } from '../external/restApi.js';
export default createStore({
    state: {
        menu: [], count: 0, kode_cabang: "", kode_divisi: "", aktif: "", log_data_info : [], log_data_transaksi : [], db: new Date().getFullYear(),
    },
    mutations: {
        SET_MENU(state, payload) {
            state.menu = payload
        },
        SET_CABANG(state, payload) {
            state.kode_cabang = payload
        },
        SET_DIVISI(state, payload) {
            state.kode_divisi = payload
        },
        SET_AKTIF(state, payload) {
            state.aktif = payload
        },
        SET_LOG_INFO(state, payload) {
            state.log_data_info = payload
        },
        SET_LOG_TRANSAKSI(state, payload) {
            state.log_data_transaksi = payload
        },
        SET_CABANG_DIVISI(state, payload){
            state.kode_cabang_divisi = payload
        },
        SET_DB(state, payload) {
            state.db = payload
        },
    },
    actions: {
        addMenu(context, payload) {
            return new Promise((resolve, reject) => {
                // console.log("payload", payload);
                if(payload.level == 1){
                    getFeed("/module/users?aktif="+payload.aktif, payload.token).then(result => {
                        this.commit("SET_MENU",result.data.data);
                        resolve(result)
                    });
                }else{
                    getFeed("/module/users?kode_cabang="+payload.kode_cabang+"&kode_divisi="+payload.kode_divisi+"&aktif="+payload.aktif, payload.token).then(result => {
                        this.commit("SET_MENU",result.data.data);
                        // console.log(JSON.stringify(result.data.data)+' data')
                        resolve(result)
                    });
                }
            })
        },
        setCabang(context, payload) {
            context.commit('SET_CABANG', payload)
        },
        setDivisi(context, payload) {
            context.commit('SET_DIVISI', payload)
        },
        setAktif(context, payload) {
            context.commit('SET_AKTIF', payload)
        },
        setLogInfo(context, payload) {
            context.commit('SET_LOG_INFO', payload)
        },
        setLogTransaksi(context, payload) {
            context.commit('SET_LOG_TRANSAKSI', payload)
        },
        setCabangDivisi(context, payload){
            console.log("payload", payload);
            context.commit('SET_CABANG_DIVISI', payload)
        },
        setDb(context, payload){
            console.log("payload", payload);
            context.commit('SET_DB', payload)
        }
    },
    getters: {
        kode_cabang: function (state) {
            return state.kode_cabang;
        },
        kode_divisi: function (state) {
            return state.kode_divisi;
        },
        aktif: function (state) {
            return state.aktif;
        },
        menu: function (state) {
            return state.menu;
        },
        log_data_info: function(state){
            return state.log_data_info;
        },
        log_data_transaksi: function(state){
            return state.log_data_transaksi;
        },
        kode_cabang_divisi: function(state){
            return state.kode_cabang_divisi;
        },
        db: function (state) {
            return state.db;
        },
    }
})
