exports.list = {
    path: '/nota_dk_hutang',
    name: 'nota_dk_hutang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/nota_dk_hutang/List.vue"),
}
exports.show = {
    path: '/nota_dk_hutang/show/:no_kk',
    name: 'nota_dk_hutang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/nota_dk_hutang/Show.vue"),
}