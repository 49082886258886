import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue3-cookies';
import PerfectScrollbar from "vue3-perfect-scrollbar";
import store from './store'

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/vendors/base/vendors.bundle.css';
import './assets/default/base/style.bundle.css';
import './assets/vendors/custom/fullcalendar/fullcalendar.bundle.css';
import './assets/vendors/custom/datatables/datatables.bundle.css';
import './assets/modal.css';
import './assets/dropdown.css';

const app = createApp(App)
// Or to set default config:
app.config.warnHandler = function (msg, vm, trace) {
  return null
}
app.use(VueCookies, {
    expireTimes: "30d",
});


//app.use(asyncComputed);

app.use(store);
app.use(VueSweetalert2);
app.use(router);
app.use(PerfectScrollbar);
app.mount('#app')
