exports.list = {
  path: '/claim',
  name: 'claim', 
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/claim/List.vue")
};
exports.show = {
  path: '/claim/show/:kode',
  name: 'claim_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/claim/Show.vue"),
}
  